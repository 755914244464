<template>
  <div class="about-area pt-20 pb-90">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-6">
          <div class="ab-wrapper pos-rel">
            <div class="ab-since">
              <h4>
                Since <br />
                2016
              </h4>
            </div>
            <div class="row gx-2 mb-30">
              <div class="col-6">
                <div class="ab-img img-filter">
                  <img src="../../assets/img/about/abs-sm3.jpg" alt="" />
                </div>
              </div>
              <div class="col-6">
                <div class="ab-img img-filter">
                  <img src="../../assets/img/about/abs-sm1.jpg" alt="" />
                </div>
              </div>
              <div class="col-6">
                <div class="ab-img img-filter">
                  <img src="../../assets/img/about/abs-sm2.jpg" alt="" />
                </div>
              </div>
              <div class="col-6">
                <div class="ab-img img-filter">
                  <img src="../../assets/img/about/abs-sm4.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6">
          <div class="ab-box pl-50 mb-30">
            <div class="sec-wrapper">
              <h5>About Us</h5>
              <h2 class="section-title">
                We are trusted by more than <span>20,000 clients.</span>
              </h2>
            </div>
            <div class="ab-item-box mt-40 mb-30">
               <div class="ab-items fix mb-20">
                <div class="ab-icon f-left">
                  <i class="flaticon-trustworthy"></i>
                </div>
                <div class="ab-item-text fix">
                  <h3 class="ab-item-title">Trusted Services</h3>
                  <p>
                    GulfTIC Certification is a pioneer Gulf origin conformity
                    assessment provider for companies importing and exporting
                    their products from all over the world.
                  </p>
                </div>
              </div>
              <div class="ab-items fix mb-20">
                <div class="ab-icon f-left">
                  <i class="flaticon-honesty"></i>
                </div>
                <div class="ab-item-text fix">
                  <h3 class="ab-item-title">Satisfied Clients</h3>
                  <p>
                     Where Satisfaction Meets Service Excellence.
                  </p>
                </div>
              </div>

            </div>
            <div class="ab-btn" v-if="$route.path!=='/about'">
              <router-link to="/about" class="tp-btn">Learn More</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>