<template>
    <Header/>
    <ContactMap/>

    <ContactArea/>
    <ContactBranch/>
    <Footer/>
</template>

<script>
import Header from '../../components/Home-two/HomeTwoHeader.vue';
import ContactArea from '../../components/Contact/ContactArea.vue';
import ContactMap from '../../components/Contact/ContactMap.vue';
import ContactBranch from '@/components/Contact/ContactBranch.vue';
import Footer from '../../components/Home/Footer.vue';

export default {
    name:'ContactPage',
    components:{
        Header,
        ContactArea,
        ContactMap,
        ContactBranch,
        Footer,
    }
}
</script>