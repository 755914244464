<template>
  <section class="contact-area  pb-80 fix">
    <div class="container">
      <div class="row">


          <div class="contact-info pt-100 col-md-4 col-12" :key=branch.id v-for="branch in branches">
            <div class="">
            <h2  class="section-title">{{branch.name}}</h2>
            <hr>
          </div>
            <div class="single-contact-info d-flex align-items-center" v-if="branch.mobile">
              <div class="contact-info-icon">
                <a href="#"><i class="fad fa-comments"></i></a>
              </div>
              <div class="contact-info-text mt-10">
                <span>Call Anytime</span>
                <h5>
                    <a :href="'tell:' + branch.mobile">{{ branch.mobile }}</a>

                </h5>
              </div>
            </div>
            <div class="single-contact-info d-flex align-items-center">
              <div class="contact-info-icon">
                <a href="#"><i class="fal fa-envelope"></i></a>
              </div>
              <div class="contact-info-text mt-10">
                <span>Send email</span>
                <h5>
                    <a :href="'mailto:' + branch.email">{{ branch.email }}</a>
                </h5>
              </div>
            </div>
            <div class="single-contact-info d-flex align-items-center">
              <div class="contact-info-icon">
                <a href="#"><i class="fal fa-map-marker-alt"></i></a>
              </div>
              <div class="contact-info-text mt-10">
                <span> Office</span>
                <h5>
                  <a
                  >
                  {{branch.location}}</a
                  >
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>

  </section>
</template>

  <script>
export default {
  name: "ContactBranch",
  data() {
     return {
       branches:[
                {
                    id:'1',
                    mobile:' +966595406064',
                    name:'Saudi Branch',
                    email:"khaled@gulftic.com",
                    des:'GulfTIC Certification LLC',
                    location:'Office 205, 2nd Floor, 7060 Building,Takhassusi Street,Riyadh, Saudi Arabia.'
                },
                {
                    id:'2',
                    phone:'+20223148010',
                    mobile:"+201033011300",
                    email:"omar@gulftic.com",
                    name:'Egypt Branch',
                    des:"GulfTIC Certification",
                    location:'28D Sama Towers, Ring Road,New Maa’di, Egypt.'

                },
                {
                    id:'3',
                    phone:'+63270079020',
                    mobile:"+639154423021",
                    email:"paola@gulftic.com",
                    name:'Philippines Branch',
                    des:"GulfTIC Testing and Inspection Services",
                    location:'Unit B 3F Caratland Building, 1941 Taft Avenue,Pasay City, Metro Manila, Philippines.'
                },
                {
                    id:'4',
                    mobile:"+91-9860023270",
                    name:'India Branch',
                    email:"nizar@gulftic.com",
                    des:"GulfTIC Testing and Inspection Services",
                    location:'Office No. 1009, Haware Infotech Park, Sector No. 30A, Vashi,Navi Mumbai,Maharashtra, India.'

                }, {
                    id:'5',
                    email:"info@gulftic.com",
                    name:'Europe Branch',
                    des:"GulfTIC Europe Certification KFT",
                    location:'Budapest, Villányi út 47 1118'

                },
            ]
     };
   },
};
</script>