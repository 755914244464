<template>
    <Header/>
    <BlogArea/>
    <Footer/>
    <video-embed src="https://www.youtube.com/watch?v=s4ObxcdXoFE"></video-embed>
</template>

<script>
import Header from '../../components/Home-two/HomeTwoHeader.vue';
import BlogArea from '../../components/Blog/BlogArea.vue';
import Footer from '../../components/Home/Footer.vue';

export default {
    name:'BlogPage',
    components:{
        Header,

        BlogArea,
        Footer,
    }
}
</script>