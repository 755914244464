<template>
   <section class="blog__area pt-20 pb-120">
     <div class="container">
       <div class="row">
         <div class="col-xl-6 col-lg-6 col-12" :key="blog.id" v-for="blog in blogData">
           <div class="blog__wrapper pr-50">
             <div class="blog-wrap blog-item mb-50">
               <div class="blog-thumb blog-video">
                 <img :src="getImgUrl(blog.img)" alt="blog">
               </div>
               <div class="blog-details blog-details-2">
                 <ul class="blog-meta">
                   <li><a href="#"><i class="fal fa-clock"></i>{{ blog.date }}</a></li>
                   <li><a href="#"><i class="fal fa-user-circle"></i> {{ blog.location }}</a></li>
                 </ul>
                 <h3 class="blog-title">
                   <router-link to="/blog-details">{{ blog.title }}</router-link>
                 </h3>
                 <p class="collapsedTxt" :class="blog.id === activeBlog ? 'content-holder' : ''">
                   {{ blog.des }}
                 </p>
                 <div class="blog-btn mt-25">
                   <button class="tp-btn" @click="toggleDescription(blog.id)">
                     {{ activeBlog === blog.id ? 'Show Less' : 'Read More' }}
                   </button>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </section>
 </template>

 <script>
 export default {
   name: "BlogArea",
   data() {
     return {
       activeBlog: null, // Track the ID of the active blog description
       blogData:[
                {
                    id:'1',
                    img:'ev1.jpg',
                    date:'15 March 21',
                    title:'SASO EU Workshop',
                    des:'The SASO Workshop held in Munich marked a promising start, providing attendees with a valuable gateway to gain insights into the intricate landscape of Saudi regulations and burgeoning trading prospects. With Munich serving as the first stop of the SASO EU Workshop, this event served as a conduit for fostering connections between European traders, Exporters, Manufacturers, for the Saudi Arabian market. Notably present were distinguished figures including His Excellency Dr. Saad Alkasabi - Governor of Saudi Standards who brought his expertise to the forefront. Additionally, the event was graced by the presence of GulfTIC Managing Directors and Director of Business Development namely, Eng. Victorino Abejero MD of Operations, Dr. Yahya Ali Al Jasmi MD of Administration, and Eng. Airen Tudtud the Director of Business Development of GulfTIC Certification. Also, through the presence of the Top officers of SASO, they have collectively underscored the strategic significance of the occasion. The workshops proceedings exemplified a fusion of knowledge sharing and networking, encapsulating the spirit of collaboration and exploration that defines contemporary global business interactions.',
                    location:'Munich'
                },
                {
                    id:'2',
                    img:'ev2.jpg',
                    date:'28 March 21',
                    title:'SASO EU Workshop',
                    des:"Moving forward on our journey, GulfTIC is poised to be your guiding light. Our shared mission is to unravel the intricacies of regulatory frameworks, simplifying the path for your products and services With Milan as the our 2nd stop, this event takes on an even more exciting and , as we converge to decode the complexities that often discuss for market entry. GulfTIC, along with the expertise of SASO, is committed to ensuring a seamless transition for your offerings into Saudi market. Our goal is not only to disseminate information but to empower you with actionable insights. Through a strategic blend of workshops, discussions, and opportunities, attendees will gain a comprehensive understanding of the compliance prerequisites and certifications that form the fundamental of market entry. The synergy between GulfTIC and SASO is more than just collaborative; it's transformative. Both entities bring to the table a deep-rooted commitment to uncompromising quality and a shared vision of facilitating growth and innovation. The expertise that each brings will undoubtedly enrich the sessions, offering attendees firsthand the strategic approach necessary to navigate the market regulations.",
                    location:'Milan'

                },
                {
                    id:'3',
                    img:'ev3.jpg',
                    date:'22 March 21',
                    title:'SASO EU Workshop.',
                    des:"Concluding our extraordinary journey in the vibrant city of Madrid marks the culmination of an exhilarating collaboration. The SASO workshop, which embarked on its final city, has left an indelible mark, infusing us with a sense of wonder and achievement. The depth of gratitude we feel extends to all the passionate participants who shared their energy, and the insightful discussions that enriched our collective understanding. In our esteemed team, two remarkable Managing Directors, Mr. Victorino and Dr. Yahya, stood as pillars of knowledge throughout this journey. Their dedication and strategic insights played a pivotal role in shaping the workshop's trajectory. Alongside them, Ms. Airen, our Director of Business Development, brought her dynamic vision to the forefront, weaving connections and fostering growth. their visionaries were not alone in this endeavor; they were joined by the esteemed officials of SASO who lent their wisdom and expertise to the journey. Madrid, with its rich history and vibrant culture, served as the stage for our final discussion about Saudi Regulation and Easy Access to the Saudi Market. It encapsulated the spirit of collaboration and learning that has defined our journey from the very beginning. As we gather our thoughts and reflect on the ground we've covered, it's evident that the workshop was not just an event but a transformative experience. It unified the European business to easily access the Saudi Arabian market and meld them into a tapestry of innovation, regulatory understanding, and shared economic purpose.",
                    location:'Madrid'

                },
                {
                    id:'4',
                    img:'ev3.png',
                    date:'22 March 22',
                    title:'SASO Workshop',
                    des:"SASO – Saudi Standards Metrology and Quality Organization, in partnership with GULFTIC Certification has successfully organized a Workshop last April 4, 2018 at the Hotel Intercontinental-Dubai Festival City as part of a series of talks being conducted by SASO in major cities in Saudi, UAE and soon in China to discuss SASO’s Product Conformity Schemes including Gulf Conformity Mark (G Mark), Quality Mark and IECEE-based Certification. The Dubai Workshop was well attended by more than 150 participants coming from various sectors such as manufacturers, trade organizations, regional headquarters, logistics companies, GULFTIC Management and Staff and a strong SASO delegation headed by Engr. Sultan Abu Koshim, who gave an overview of SASO’s significant role in elevating the standards and quality of products and services available in the Kingdom of Saudi Arabia through their different Product Conformity Schemes, the most recent of which is the upcoming implementation of the SALEEM Program.",
                    location:'Saudi'

                }, {
                    id:'5',
                    img:'ev5.png',
                    date:'22 March 22',
                    title:'Gulfood',
                    des:"Gulfood - the world’s largest annual food and beverage trade show - held at World Trade Center - Dubai, this February! The 23rd edition is the biggest yet both in terms of exhibitors and expected visitors. The show is popular across the world for promoting unmatched opportunities for networking at a central point between East and West. And this edition takes it further ahead! More than 120 national pavilions, and at least 97,000 attendees, are all set to share, learn and expand their businesses at the Dubai World Trade Centre. This year also sees new participants from as far a field as Estonia, Serbia and Slovakia. Products across eight primary market sectors, such as Beverages; Dairy; Fats & Oils; Health, Wellness & Free-From; Pulses, Grains & Cereals; Meat & Poultry; Power Brands and World Food will be featured over those five days.GulfTIC proud to receive the award by MOIAT Director General Mr. Abdullah Al Maeni at Global Halal Industry Platform Dubai, GulfTIC also opened a stall in Gulfood.",
                    location:'Madrid'

                },
            ]
     };
   },
   methods: {
     getImgUrl(pic) {
       return require("../../assets/img/blog/" + pic);
     },
     toggleDescription(blogId) {
       if (this.activeBlog === blogId) {
         // If the clicked blog is already active, deactivate it
         this.activeBlog = null;
       } else {
         // Otherwise, activate the clicked blog
         this.activeBlog = blogId;
       }
     },
   },
 };
 </script>

 <style scoped>
 .collapsedTxt {
   height: 50px;
   overflow: hidden;
 }

 .content-holder {
   overflow: unset !important;
   height: auto !important;
   transition: height 0.2s ease-in-out;
 }
 </style>
