<template>
        <section class="service-detals pt-20 pb-100 fix">
            <div class="container">
               <div class="row">
                  <div class="col-xxl-8 col-xl-8 col-lg-8">
                        <div class="develop-wrapper">
                           <div class="develop-thumb">
                              <img src="../../assets/img/service/service-img-5.jpg" alt="">
                           </div>
                           <div class="develop-content">
                              <h3 class="service-title">{{ service.name }}</h3>
                              <p v-if="service.des" v-html="service.des "></p>
                           </div>
                        </div>

                        <div class="planning-wrapper pt-50">
                           <div class="row">

                              <div class="col-xxl-12 col-xl-12 col-lg-12">
                                    <div class="plan-content">
                                       <h4 v-if="service.certificate_name">{{ service.certificate_name }}</h4>
                                       <ul>
                                          <li :key="certificate.id" v-for="certificate  in service.certificates">{{certificate.name}}</li>

                                       </ul>
                                    </div>
                              </div>


                              <div class="col-xxl-12 col-xl-12 col-lg-12 pt-50" v-if="service.requirements">
                                    <div class="req-content">
                                       <h4>Application Requirements</h4>
                                       <ul>
                                          <li :key="requirement.id" v-for="requirement  in service.requirements">{{requirement.name}}</li>

                                       </ul>
                                    </div>
                              </div>
                           </div>
                        </div>


                  </div>
                   <div class="col-xxl-4 col-xl-4 col-lg-4">
                        <div class="sidebar-wrap">
                           <div class="widget_categories grey-bg" v-if="service.categories">
                              <h4 class="bs-widget-title pl-20">Categories</h4>
                              <ul>
                                    <li :key="category.id" v-for="category  in service.categories"><router-link to="/services-details" target="_blank">{{category.name}}</router-link></li>

                              </ul>
                           </div>
                           <div class="widget-btn mt-30">
                              <a href="mailto:info@gulftic.com" class="tp-btn w-100">Apply Now</a>
                           </div>
                        </div>
                  </div>
               </div>
            </div>
         </section>
</template>

<script>
import serviceData from "@/assets/js/services.json";

export default {
    name:'ServicesDetailsArea',
    data() {
    return {
      service: {},
    };
  },
  mounted() {
    const serviceId = this.$route.params.id; // Replace with the desired item's ID
    if(serviceId){
      this.service = serviceData.find((item) => item.id == serviceId);

    }
  },
}
</script>