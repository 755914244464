<template>
        <section class="portfolio-area pt-120 pb-70">
            <div class="container">
               <div class="row mb-40">
                  <div class="col-12">
                     <div class="sec-wrapper text-center">
                        <h5>Product Categories</h5>
                        <h2 class="section-title">Explore Our Categories.</h2>
                     </div>
                  </div>
               </div>

               <div id="portfolio-grid" class="row row-portfolio tab-content">


               <div className="tab-pane fade show active" id="nav-all" role="tabpanel"   aria-labelledby="nav-all-tab">
                   <div class="container">
                      <div class="row">
                        <div v-for="(portfolioItem,imageIndex) in portfolioData" :key="portfolioItem.id" class="col-lg-3 col-md-6">
                        <div class="tportfolio mb-30">
                           <div class="tportfolio__img">
                              <button class="popup-image">
                                 <img :src="typeof portfolioItem.image === 'string' ? portfolioItem.image
                                 : portfolioItem.image" />
                              </button>
                           </div>
                           <div class="tportfolio__text">
                               <h5 class="tportfolio-title">
                                  <router-link :to="{name:'category-details', params: {id: portfolioItem.id}}" ><span v-html="portfolioItem.title"></span></router-link>
                               </h5>
                               <div class="portfolio-plus">
                                 <button @click="() => showImg(imageIndex)">
                                    <i class="fal fa-plus"></i>
                                  </button>
                              </div>
                           </div>
                        </div>
                       </div>
                     </div>
                   </div>
                </div>

                   <div className="tab-pane fade" id="nav-design" role="tabpanel" aria-labelledby="nav-design">
                      <GalleryOne/>
                   </div>

                   <div className="tab-pane fade" id="nav-logo" role="tabpanel" aria-labelledby="nav-logo">
                      <GalleryTwo/>
                   </div>

                   <div className="tab-pane fade" id="nav-business" role="tabpanel" aria-labelledby="nav-business">
                     <GalleryThree/>
                   </div>

                   <div className="tab-pane fade" id="nav-agency" role="tabpanel" aria-labelledby="nav-agency">
                     <GalleryFour/>
                  </div>

               </div>
                <vue-easy-lightbox
                  :visible="visible"
                  :imgs="portfolioData.map(img => img.image)"
                  :index="index"
                  @hide="handleHide"
               ></vue-easy-lightbox>
            </div>
         </section>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';
import GalleryOne from './GalleryFilterItems/GalleryOne.vue';
import GalleryTwo from './GalleryFilterItems/GalleryTwo.vue';
import GalleryThree from './GalleryFilterItems/GalleryThree.vue';
import GalleryFour from './GalleryFilterItems/GalleryFour.vue';

export default {
   name:'HomePortfolio',
   components: {
    VueEasyLightbox,
    GalleryOne,
    GalleryTwo,
    GalleryThree,
    GalleryFour,
  },
   data () {
      return {
        visible: false,
        index: 0,
         portfolioData:[
            {
               id:'1',
               image:require(`@/assets/img/portfolio/p1.jpg`),
               title:'LOW VOLTAGE EQUIPMENT',
               subtitle:'Busines, Agency'
            },
            {
               id:'2',
               image:require(`@/assets/img/portfolio/p2.jpg`),
               title:'BOTTLES',
               subtitle:'Consultation, Idea'
            },
            {
               id:'3',
               image:require(`@/assets/img/portfolio/p3.jpg`),
               title:'COSMETICS,<br> PERFUMES',
               subtitle:'Deaign, Brand'
            },
            {
               id:'4',
               image:require(`@/assets/img/portfolio/p4.jpg`),
               title:'FOOD CONTACT MATERIALS',
               subtitle:'Print, Market'
            },
            {
               id:'5',
               image:require(`@/assets/img/portfolio/p5.jpg`),
               title:'LAMPS AND<br> LUMINARIES',
               subtitle:'Logo, Busines'
            },
            {
               id:'6',
               image:require(`@/assets/img/portfolio/6.jpg`),
               title:'CONSTRUCTION MATERIALS',
               subtitle:'Market, Idea'
            },
            {
               id:'7',
               image:require(`@/assets/img/portfolio/7.jpg`),
               title:'LPG CYLINDERS , <br> GAS POWERED APPLIANCES',
               subtitle:'Logo, Busines'
            },
            {
               id:'8',
               image:require(`@/assets/img/portfolio/8.jpg`),
               title:'RESTRICTION OF HAZARDOUS SUBSTANCES',
               subtitle:'Market, Idea'
            },
         ]
      }
   },
   methods: {
      showImg(index) {
        this.index = index
        this.visible = true
      },
      handleHide() {
        this.visible = false
      }
    }
}
</script>