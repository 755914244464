<template>
    <Header/>
    <ServicesDetailsArea/>
    <Footer/>
</template>

<script>
import Header from '../../components/Home-two/HomeTwoHeader.vue';
import ServicesDetailsArea from '../../components/ServicesDetails/ServicesDetailsArea.vue';
import Footer from '../../components/Home/Footer.vue'

export default {
    name:'servicesDetails',
    components:{
        Header,
        ServicesDetailsArea,
        Footer
    }
}
</script>