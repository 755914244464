<template>
  <footer
    class="footer"
    data-bg-color="#222429"
    :style="{
      backgroundImage: 'url(' + require('../../assets/img/bg/04.png') + ')',
    }"
  >
    <div class="footer-top pt-115 pb-90">
      <div class="container">
        <div class="row">
          <div class=" col-lg-4 col-md-6">
            <div class="footer-widget mb-30">
              <div class="footer-logo mb-30">
                <router-link to="/">
                  <img src="../../assets/img/logo/logo.png" alt="" />
                </router-link>
              </div>
              <div class="footer-text mb-20">
                <p>
                  GulfTIC Certification is a pioneer Gulf origin conformity
                  assessment provider for companies importing and exporting
                  their products from all over the world..
                </p>
              </div>
            </div>
          </div>
          <div class=" col-lg-4 col-md-6 col-md-6">
            <div class="footer-widget mb-30 pl-20">
              <h3 class="footer-title">Quick Links</h3>
              <div class="footer-menu-2">
                <ul>
                  <li><router-link to="/home">Home</router-link></li>
                  <li><router-link to="/about">About us</router-link></li>
                  <li><router-link to="/services">Services</router-link></li>
                  <li><router-link to="/blog">News</router-link></li>
                  <li><router-link to="/contact">Contact us</router-link></li>

                </ul>
              </div>
            </div>
          </div>

          <div class=" col-lg-4 col-md-6 d-lg-block">
            <div class="footer-widget mb-30">
              <h3 class="footer-title">Social Links</h3>
              <div class="footer-instagram">
                <div class="footer-social">
              <a target="_blank" href="https://www.facebook.com/gulfticgroup/"><i class="fab fa-facebook-f"></i></a>
              <a target="_blank" href="https://www.linkedin.com/company/gulfticgroup/"><i class="fab fa-linkedin"></i></a>
              <a target="_blank" href="https://twitter.com/gulfticgroup"><i class="fab fa-twitter"></i></a>
              <a target="_blank" href="https://www.instagram.com/gulfticgroup/"><i class="fab fa-instagram"></i></a>
            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-area pt-25 pb-20">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="footer-copyright">
              <p class="white-colo text-center">
                Copyright ©2023 Gulftic. All Rights Reserved
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>

    <vue-easy-lightbox
      :visible="visible"
      :imgs="galleryData.map((img) => img.img)"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
  </footer>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  name: "HomeFooter",
  components: {
    VueEasyLightbox,
  },
  data() {
    return {
      visible: false,
      index: 0,
      galleryData: [
        {
          id: "1",
          img: require(`@/assets/img/instagram/ins1.jpg`),
        },
        {
          id: "2",
          img: require(`@/assets/img/instagram/ins2.jpg`),
        },
        {
          id: "3",
          img: require(`@/assets/img/instagram/ins3.jpg`),
        },
        {
          id: "4",
          img: require(`@/assets/img/instagram/ins4.jpg`),
        },
        {
          id: "5",
          img: require(`@/assets/img/instagram/ins5.jpg`),
        },
        {
          id: "6",
          img: require(`@/assets/img/instagram/ins6.jpg`),
        },
      ],
    };
  },
  methods: {
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
};
</script>