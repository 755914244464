<template>
    <Header/>
    <HomeTwoSlider/>
    <About/>
    <Services/>

    <Portfolio/>
    <Counter/>
    <Blog/>
    <Footer/>
</template>

<script>
import Header from '../../components/Home-two/HomeTwoHeader.vue';
import HomeTwoSlider from '../../components/Home-two/HomeTwoSlider.vue';
import Services from '../../components/Home/Services.vue';
import About from '../../components/Home/About.vue';
import Counter from '../../components/Home/Counter.vue';
import Portfolio from '../../components/Home/Portfolio.vue';
import Blog from '../../components/Home/Blog.vue';
import Footer from '../../components/Home/Footer.vue';

export default {
    name:'HomePage',
    components:{
        Header,
        HomeTwoSlider,
        Services,
        About,
        Counter,
        Portfolio,

        Blog,
        Footer,
    }
}
</script>