<template>
  <div
    class="main-services grey-bg pt-20 pb-90"
    :style="{
      backgroundImage:
        'url(' + require('../../assets/img/pattern/pt1.png') + ')',
    }"
  >
    <div class="container">
      <div class="row mb-60">
        <div class="col-12">
          <div class="sec-wrapper text-center">
            <h2 class="section-title">Explore Our Services.</h2>
          </div>
        </div>
      </div>
      <div class="row text-center">
        <div
          v-for="service in servicesData"
          :key="service.id"
          class="col-xl-4 col-lg-4 col-md-6 mb-30"
        >
          <div class="mfbox mfbox-white">
            <div class="mf-shape"></div>
            <div class="mfbox__icon mb-15">
              <i :class="service.icon"></i>
            </div>
            <div class="mfbox__text">
              <router-link  :to="{name:'services-details', params: {id: service.id}}">
              <h3 class="mf-title" v-html="service.title"></h3>
            </router-link>
              <p class="desTrim">{{ service.des }}</p>
            </div>
            <div class="mf-btn">
              <router-link class="squire-btn" :to="{name:'services-details', params: {id: service.id}}">
                <i class="fal fa-angle-right"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesArea",
  data() {
    return {
      servicesData: [
      {
          id: "1",
          icon: "flaticon-insight",
          title: "Halal Services",
          des:"Halal is an Arabic word meaning lawful or permitted. In relation to food and cosmetic products, Halal means products that are allowed to be consumed or used by humans according to Shariah (Islamic Law)."
        },
        {
          id: "2",
          icon: "flaticon-insight",
          title: "ECAS Services",
          des: "The Emirates Conformity Assessment Scheme or ECAS is a Product Certification scheme for government-regulated products as mandated by the Federal Law 28 of 2001.",
        },
        {
          id: "3",
          icon: "flaticon-remarketing",
          title: "ECAS OBD",
          des:"UAE have adopted laws that mandate disposable polyolefin plastics to be oxo-biodegradable."
        },
        {
          id: "4",
          icon: "flaticon-remarketing",
          title: "EQM Services",
          des:"The system of obtaining the license to use the Emirates Quality Mark or EQM is a Product Certification scheme involving a comprehensive evaluation of the product as well as the Quality Management System."
        },
        {
          id: "5",
          icon: "flaticon-technical-support",
          title: "EESL Services",
          des:"Energy Efficiency Standards and Labeling or EESL is a set of UAE Technical Regulations for electrical appliances. This program requires specific information to be included on the product label."
        },

        {
          id: "5",
          icon: "flaticon-remarketing",
          title: "GMARK Services",
          des:"As an important part of realizing the goals of the Gulf Cooperation Council (GCC) of a unified economic agreement among its member states, (G Mark) was adopted in the region as a common market requirement"
        },
        {
          id: "6",
          icon: "flaticon-technical-support",
          title: "SQM Services",
          des:"Saudi Quality Mark is the exclusive property of SASO, being granted to be used on products that have demonstrated compliance to Saudi standards, manufactured in a facility that has an effective Management System."
        },
        {
          id: "7",
          icon: "flaticon-technical-support",
          title: "SASO OBD",
          des:"KSA have adopted laws that mandate disposable polyolefin plastics to be oxo-biodegradable."
        },
        {
          id: "8",
          icon: "flaticon-insight",
          title: "SASO COC",
          des:"The government of KSA is strictly monitoring the entry of consumer goods into their ports, ensuring that only products that meet the requirements of local regulations & international standards are allowed into their markets."
        },


        {
          id: "9",
          icon: "flaticon-insight",
          title: "MCS Services",
          des:"Mandatory Certification Scheme in KSA regulating products according to technical regulations or standards of the International Electrotechnical Commission (IEC) System of Conformity Assessment Schemes for Electrotechnical Equipment."
        },
        {
          id: "10",
          icon: "flaticon-remarketing",
          title: "SABER",
          des:"On-line verification system integrating different government entities in KSA that will ensure products compliance to Saudi regulations before entry into the Saudi Market"
        },
        {
          id: "12",
          icon: "flaticon-technical-support",
          title: "SFDA Shipment CoC",
          des:"The Saudi Food and Drug Authority (SFDA) is the Regulatory Authority in KSA ensuring safety of food, drug for human and animal, biological and chemical substance as well as electronic devices that are related to human health. GulfTIC offers Per Consignment CoC Services"
        },
      ],
    };
  },
};
</script>