<template>
    <div class="mission-area  pt-90 pb-90">
           <div class="container">

              <div class="row justify-content-center ">
                 <div v-for="img in images" :key="img.id" class="col-xl-6 col-lg-6 col-md-6 col-12">
                      <div class="row align-items-top">
                        <div class="col-3">
                          <img :src="getImgUrl(img.image)" alt="" class="w-100">
                       </div>
                       <div class="col-9">
                        <h2>{{img.title}}</h2>
                        <p> {{img.des}}</p>
                       </div>
                      </div>
                 </div>
              </div>
           </div>
        </div>
</template>

<script>
export default {
  name:'imgArea',
  data () {
      return {
          images: [
               {
               id: "1",
               image: "vision.png",
               title:"Vision",
               des: `To be a market leader in providing certification,
inspection, testing, and training services, reputed
for its impartiality, professional ethics and high
standards, and considered as a reference point for
assessment of quality, safety and environmental
sustainability`,
               },
               {
               id: "1",
               title:"Mission",
               image: "target.png",
               des: `To put a premium on quality safety and
sustainability of good sand services, in effect
protecting the manufacturers and service
providers by helping them deliver quality output
in compliance to regulatory requirements, and the
consumers by ensuring their satisfaction,
promoting free trade inthe international marketto
boost the global economy, while fostering a
healthy and sustainable environment.`,
               },

         ],
      }
  },
  mounted(){
   const values = ['$1.3', '-$1.9', '-$0.0', '-$2.0'];
   this.reportExpenses(values)
  },
   methods: {
       getImgUrl(pic) {
       return require("../../assets/img/mission/" + pic);
       },
        reportExpenses( arr ) {

let negativeCount = 0;
let negativeSum = 0;

for (const value of arr) {
  if (value.startsWith("-$") && value !== "-$0.0") {
    negativeCount++;
    negativeSum += parseFloat(value.replace('-$', ''));
  }
}
return {  negativeCount, negativeSum};
}



   },
}
</script>
<style scoped>
.mission-area{
   background: #F5F5F5;

}
</style>