<template>
    <Header/>
    <About/>
    <Mission/>
    <About2 />
    <About4 />
    <About3 />
    <Testimonial/>

    <Footer/>
</template>

<script>
import Header from '../../components/Home-two/HomeTwoHeader.vue';
import About from '../../components/Home/About.vue';
import Mission from '../../components/about/mission.vue';
import About2 from '../../components/about/about2.vue';
import About4 from '../../components/about/about4.vue';
import About3 from '../../components/about/about3.vue';
import Testimonial from '../../components/Home-two/Testimonials.vue'

import Footer from '../../components/Home/Footer.vue';

export default {
    name:'AboutPage',
    components:{
        Header,
        About,
        Mission,
        About2,
        About4,
        Testimonial,
        About3,
        Footer,
    }
}
</script>