<template>
   <div
     class="main-services grey-bg pt-120 pb-90"
     :style="{
       backgroundImage:
         'url(' + require('../../assets/img/pattern/pt1.png') + ')',
     }"
   >
     <div class="container">
       <div class="row mb-60">
         <div class="col-12">
           <div class="sec-wrapper text-center">
             <h2 class="section-title">MANAGEMENT STATEMENT ABOUT IMPARTIALITY
</h2>
<h5>The operation of GulfTIC Certification as a Conformity Assessment Body is governed by six key values:</h5>
           </div>
         </div>
       </div>
       <div class="row text-center">
         <div
           v-for="service in servicesData"
           :key="service.id"
           class="col-xl-4 col-lg-4 col-md-6 mb-30"
         >
           <div class="mfbox mfbox-white">
             <div class="mf-shape"></div>

             <div class="mfbox__text">
               <h3 class="mf-title" v-html="service.title"></h3>
               <p class="desTrim">{{ service.des }}</p>
             </div>

           </div>
         </div>
       </div>
     </div>
   </div>
 </template>

 <script>
 export default {
   name: "ServicesArea",
   data() {
     return {
       servicesData: [
         {
           id: "1",
           title: "Impartiality",
           des: "The Management of GulfTIC is highly committed to ensure that all activities by internal and external personnel working for the company are carried out without discrimination or biases due to personal, commercial, financial or other types of pressures. A committee to safeguard this impartiality of GulfTIC has been developed with balanced representation of relevant interested parties.",
         },
         {
           id: "2",
           title: "Professional Ethics",
           des:"All employees of GulfTIC conduct shall aspire to principles of loyalty, professionalism, honesty, moral integrity, confidentiality and assurance of customer’s propriety rights"
         },
         {
           id: "3",
           title: "Accountability",
           des:"All parties involved act responsibly"
         },
         {
           id: "4",
           title: "Quality",
           des:"Identifying client needs and exceed customer expectations"
         },
         {
           id: "5",
           title: "Transparency",
           des:"Operating rules must be clear, documented and known"
         },
         {
           id: "6",
           title: "Efficiency",
           des:"Continual improvement, and cost-effectiveness of delivered services must be pursued."
         },

       ],
     };
   },
 };
 </script>