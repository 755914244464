<template>
       <div class="portfolio__pagination-wrapper">
               <div class="container">
                  <div class="pagination-border pt-40 pb-40">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
        <div class="portfolio__pagination">
          <router-link :to="getPageRoute('next')" class="link-btn-2">
            <i class="fal fa-long-arrow-left"></i>
            Next
          </router-link>
        </div>
      </div>

      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
        <div class="portfolio__pagination text-end">
          <router-link :to="getPageRoute('prev')" class="link-btn-2">
            Prev <i class="fal fa-long-arrow-right"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
               </div>
         </div>
</template>

<script>
export default {
    name:'PaginationArea',

    methods: {
   getPageRoute(direction) {
  const currentId = this.$route.params.id || 0;
  const newId = direction === 'next' ? parseInt(currentId) + 1 : parseInt(currentId) - 1;
   let newRoute
  if(newId==0 || newId==9){
     newRoute = `/category-details/${ this.$route.params.id }`;
  }  else{
     newRoute = `/category-details/${ newId }`;

  }

  console.log(newRoute); // Log the generated route
  return newRoute;
}
  },
}
</script>