<template>
  <section class="contact-area pt-20  fix">
    <div class="container">
      <div class="row justify-content-center">
        <div class="sec-wrapper text-center">
                       <h2 class="section-title">AROUND THE WORLD.</h2>
                    </div>
        <div class="col-12">
          <img :src="require(`@/assets/img/map/map.jpg`)" class="w-100" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CotactMap",
};
</script>