<template>
  <section :key="$route.params.id" class="project-details-area pt-20 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-xxl-7">
          <div class="project-big-thumb">
            <img v-if="category.image" :src="getImgUrl(category.image)" alt="" class="w-100">

          </div>
          <div class="p-details-content mb-40">
            <h3>{{ category.name }}</h3>
            <p v-html="category.des"></p>
          </div>
        </div>
        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6">
          <div class="sidebar-wrap mb-40">
            <div class="sidebar-right">
              <div class="sidebar-single">
                <label>Who should register?</label>
                <span :key="product.id" v-for="product  in category.products" class="badge rounded-pill primary-bg m-1">{{product.name}}</span>
              </div>
              <div class="sidebar-single">
                <label>What certification scheme to apply for?</label>
                <span :key="certificate.id" v-for="certificate  in category.certificates" class="badge rounded-pill primary-bg mx-1">{{certificate.name}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import categoryData from "@/assets/js/categories.json";

export default {
  name: "ProjectDetailsArea",

  data() {
    return {
      category: {},
    };
  },
  watch: {
    '$route.params.id': function (newId) {
      console.log('New ID:', newId);
      this.fetchData(newId);
    },
  },
  mounted() {
 this.getCategory()
  },
  methods:{
    getCategory(){
      const categoryId = this.$route.params.id; // Replace with the desired item's ID
    if(categoryId){
      this.category = categoryData.find((item) => item.id == categoryId);

    }
    },
    getImgUrl(pic) {
        return require("../../assets/img/portfolio/" + pic);
        },
        fetchData() {
          this.getCategory()
    },
  }

};
</script>