<template>
  <section class="contact-area pt-100 fix">
    <div class="container">
      <div class="row">
        <div class="col-xxl-5 col-xl-6 col-lg-6">
          <div class="section-title-wrapper mb-15">
            <h5 class="section-subtitle mb-20">contact with us</h5>
            <h2 class="section-title">general inquiries</h2>
          </div>
          <div class="contact-info mr-50 mr-xs-0 mr-md-0">
            <div class="single-contact-info d-flex align-items-center">
              <div class="contact-info-icon">
                <a href="#"><i class="fad fa-comments"></i></a>
              </div>
              <div class="contact-info-text mt-10">
                <span>Call Anytime</span>
                <h5>
                  <a href="tell:+97142721285">+971 4 272 1285</a> ,<a
                    href="tell:+971504736456"
                    >+971 50 473 6456</a
                  >
                </h5>
              </div>
            </div>
            <div class="single-contact-info d-flex align-items-center">
              <div class="contact-info-icon">
                <a href="#"><i class="fal fa-envelope"></i></a>
              </div>
              <div class="contact-info-text mt-10">
                <span>send email</span>
                <h5>
                  <a href="mailto:info@gulftic.com">info@gulftic.com</a>
                </h5>
              </div>
            </div>
            <div class="single-contact-info d-flex align-items-center">
              <div class="contact-info-icon">
                <a href="#"><i class="fal fa-map-marker-alt"></i></a>
              </div>
              <div class="contact-info-text mt-10">
                <span>Head office</span>
                <h5>
                  <a
                    target="_blank"
                    href="https://www.google.com/maps/place/Dhaka/@23.7806207,90.3492859,12z/data=!3m1!4b1!4m5!3m4!1s0x3755b8b087026b81:0x8fa563bbdd5904c2!8m2!3d23.8104753!4d90.4119873"
                  >
                    G02, UAE Enterprises Building, Airport Road, Garhoud, Dubai,
                    United Arab Emirates.</a
                  >
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-7 col-xl-6 col-lg-6">
          <div class="contact-form">
            <form action="mail.php" id="contact-form" method="POST">
              <div class="row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                  <input name="name" type="text" placeholder="Your Name" />
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                  <input name="email" type="email" placeholder="Email Adress" />
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                  <input name="phone" type="text" placeholder="Phone" />
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                  <input name="subject" type="text" placeholder="Subject" />
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 mb-20">
                  <textarea
                    placeholder="Write Massage"
                    name="massage"
                  ></textarea>
                </div>
                <div class="col-xxl-12 col-xl-12 mb-20">
                  <button type="submit" class="tp-btn">Send a message</button>
                </div>
              </div>
            </form>
            <p class="ajax-response"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactArea",
};
</script>