<template>
    <div class="img-area pt-120 pb-90">
           <div class="container">
              <div class="row mb-60">
                 <div class="col-12">
                    <div class="sec-wrapper text-center">
                       <h2 class="section-title">GULFTIC is an ISO 17065- Accredited Certification Body.</h2>
                    </div>
                 </div>
              </div>
              <div class="row justify-content-center align-items-center">
                 <div v-for="img in images" :key="img.id" class="col-6">
                       <div class="text-center">
                          <img :src="getImgUrl(img.image)" alt="" class="w-100">

                       </div>
                 </div>
              </div>
           </div>
        </div>
</template>

<script>
export default {
  name:'imgArea',
  data () {
      return {
          images: [
               {
               id: "1",
               image: "ab4.png",
               },


         ],
      }
  },
   methods: {
       getImgUrl(pic) {
       return require("../../assets/img/about/" + pic);
       },
   },
}
</script>