<template>
    <Header/>
    <ServicesArea/>

    <BrandArea border="brand-border" />
    <Footer/>
</template>

<script>
import Header from '../../components/Home-two/HomeTwoHeader.vue';
import ServicesArea from '../../components/Services/ServicesArea.vue';
import BrandArea from '../../components/common/BrandArea.vue';
import Footer from '../../components/Home/Footer.vue';

export default {
    name:'ServicesPage',
    components:{
        Header,
        ServicesArea,


        BrandArea,
        Footer,
    }
}
</script>