<template>
     <section class="portfolio-area pt-120 pb-70">
            <div class="container">
               <div class="row mb-40">
                  <div class="col-12">
                     <div class="sec-wrapper text-center">
                        <h5>Features Categories</h5>
                        <h2 class="section-title">Explore Our Categories.</h2>
                     </div>
                  </div>
               </div>

               <GallaryItems/>

            </div>
         </section>
</template>

<script>
import GallaryItems from '../Home/GalleryFilterItems/GalleryOne.vue';

export default {
    name:'FeaturesArea',
    components:{
       GallaryItems,
    },
}
</script>